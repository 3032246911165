import React from 'react'

const WaterHeater = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8593 7.3359C11.8593 8.44476 10.9603 9.34367 9.85148 9.34367C8.74262 9.34367 7.84371 8.44476 7.84371 7.3359C7.84371 6.22703 8.74262 5.32812 9.85148 5.32812C10.9603 5.32812 11.8593 6.22703 11.8593 7.3359ZM10.8593 7.3359C10.8593 7.89247 10.4081 8.34367 9.85148 8.34367C9.2949 8.34367 8.84371 7.89247 8.84371 7.3359C8.84371 6.77932 9.2949 6.32812 9.85148 6.32812C10.4081 6.32812 10.8593 6.77932 10.8593 7.3359Z"
      fill="#6B7073"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.16662 2C5.3382 2 4.66663 2.67157 4.66663 3.5V11.8333C4.66663 12.7187 4.92231 13.5444 5.36388 14.2405L5.42049 14.4042L5.46274 14.3897C6.06142 15.2555 6.95584 15.9011 7.99996 16.1806V17.1667C7.99996 17.4428 8.22382 17.6667 8.49996 17.6667C8.7761 17.6667 8.99996 17.4428 8.99996 17.1667V16.3303C9.05527 16.3323 9.11083 16.3333 9.16663 16.3333H10.5C10.5558 16.3333 10.6113 16.3323 10.6666 16.3303V17.1667C10.6666 17.4428 10.8905 17.6667 11.1666 17.6667C11.4428 17.6667 11.6666 17.4428 11.6666 17.1667V16.1806C12.7107 15.9011 13.6052 15.2555 14.2038 14.3897L14.2461 14.4042L14.3026 14.2406C14.7442 13.5444 15 12.7187 15 11.8333V3.5C15 2.67157 14.3284 2 13.5 2H6.16662ZM9.16663 15.3333C8.09098 15.3333 7.12866 14.8481 6.48662 14.0846C7.55639 13.8118 8.67759 13.6667 9.83328 13.6667C10.989 13.6667 12.1102 13.8118 13.18 14.0846C12.5379 14.8481 11.5756 15.3333 10.5 15.3333H9.16663ZM9.83328 12.6667C11.1809 12.6667 12.4862 12.8506 13.7252 13.195C13.9021 12.7765 14 12.3163 14 11.8333V3.5C14 3.22386 13.7761 3 13.5 3H6.16662C5.89048 3 5.66663 3.22386 5.66663 3.5V11.8333C5.66663 12.3163 5.76446 12.7765 5.9414 13.195C7.18036 12.8506 8.48568 12.6667 9.83328 12.6667Z"
      fill="#6B7073"
    />
  </svg>
)

WaterHeater.propTypes = {}

WaterHeater.defaultProps = {}

export default WaterHeater
