import React from 'react'
import Icon from '../../../../utils/icon'
import i18next from 'i18next'
import {DIALOG_TYPE} from '../../../../utils/constants'
import './style.scss'
import {hasCollar, hasEnpower} from 'lib/utils/siteDetails'

export default function getLockDetails(cb) {
  const hasCollarOrSC = hasCollar() || hasEnpower()
  const content = (
    <div className="lock__popup__wrapper">
      <div className="lock__wrapper__icon">
        <Icon src="alert" />
      </div>
      <div className="e_dialog__content">
        <p>{i18next.t('bp495')}</p>
        <p>{hasCollarOrSC ? i18next.t('bp525') : i18next.t('bp496')}</p>
      </div>
    </div>
  )

  const obj = {
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: '',
    content: content,
    buttons: [
      {
        value: i18next.t('bp46'),
        action: cb,
        disabled: false,
        className: 'bp__ab--locked',
      },
    ],
  }

  return obj
}
