import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'

const initDetails = {
  countryCode: 'US',
  featureDetails: {},
  hasAcb: false,
  hasEncharge: false,
  hasEnpower: false,
  hasGenerator: false,
  hasOjasDevice: false,
  isEmea: false,
  isEnsemble: false,
  locale: 'en',
  region: 'US',
  showStormGuard: false,
  showSavingsMode: false,
  showChargeFromGrid: false,
  showConsumption: false,
  showProduction: false,
  siteStage: 5,
  calibrationProgress: false,
  isDTSite: false,
  isDTEnabled: false,
  isHemsActivationPending: true,
  isHemsSite: false,
  isNEM3Supported: false,
  isTariffNEM3: false,
  isNEM3Site: false,
  userDetails: {
    email: 'cchakravarti@enphaseenergy.com',
    isDemoUser: false,
    isHost: false,
    isOwner: false,
    isInstaller: false,
    isMaintainer: false,
    isEnphaseUser: false,
  },
  ownerOrHostMaskedEmail: null,
  restrictCfg: false,
  acceptedGICDisclaimer: false,
  gridInterconnectionDetails: {
    capacity: null,
    status: null,
  },
  siteStatus: null,
  isHemsActivatedFromUI: false,
}

const initialState = {
  loading: true,
  details: initDetails,
}

export const siteSettingsSlice = createSlice({
  name: 'siteSettings',
  initialState,
  reducers: {
    setSiteSetting: (state, action) => {
      const {payload} = action
      const states = {...state}

      return updateSpecificKeyValue(payload, states)
    },
    setSiteSettingDetails: (state, action) => {
      const {payload} = action
      const {details} = state

      updateSpecificKeyValue(payload, details)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setSiteSetting, setSiteSettingDetails} = siteSettingsSlice.actions
export const siteSettingsSelector = state => state.system.siteSettings
export default siteSettingsSlice.reducer
